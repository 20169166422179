import { Controller } from '@hotwired/stimulus';
import ddslick from '../libraries/jquery.ddslick.min'

export default class extends Controller {
  connect() {
    this.feedback_collection_select();
    this.collection_page_select();
  }

  feedback_collection_select(){
    const ddData = [
      {
        text: "Video",
        value: 'video',
        selected: false,
        imageSrc: "https://ot-public-files.s3.ca-central-1.amazonaws.com/images/video_select.svg"
      },
      {
        text: "Written",
        value: 'written',
        selected: false,
        imageSrc: "https://ot-public-files.s3.ca-central-1.amazonaws.com/images/text_select.svg"
      }]
    // feedback_collection select
    const select = $('#selectImg');
    select.ddslick({
      data: ddData,
      imagePosition: "left",
      selectText: "Select feedback type",
      onSelected: (data) => {
        $("#feedback_collection_feedback_type").val(data.selectedData.value)
        select.val = data.selectedData.value;
      }
    });
  }

  collection_page_select(){
    const options = $('.selectVideo option');
    const values = $.map(options ,function(option) {
      return {
        text: option.text,
        value: option.value
      }
    });

    $('#selectVideo').ddslick({
      data: values,
      selectText: "Select",
      onSelected: (data) => {
        document.querySelector(
            'input[name="collection_page[video_feedback_collection_id]"]',
        ).value = data.selectedData.value;
        console.log($(".selectVideo"))
      }
    });

    const optionsText = $('.selectText option');

    const valuesText = $.map(optionsText ,function(option) {
      return {
        text: option.text,
        value: option.value
      }
    });

    $('#selectText').ddslick({
      data: valuesText,
      selectText: "Select",
      onSelected: (data) => {
        document.querySelector(
            'input[name="collection_page[written_feedback_collection_id]"]',
        ).value = data.selectedData.value;
        console.log($(".selectText"))
      }
    });
  }
}
