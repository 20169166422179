import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = ['subMenu'];

  connect() {
    console.log('Connect sidebar');
    $(
      "a.tabs-list[href='" +
        window.location.pathname.split('?')[0] +
        "']",
    ).addClass('active');
  }

  showMenu() {
    console.log('Show menu');
    $('#sub_nav_menu').removeClass('d-none');
  }

  hideMenu() {
    console.log('Hide menu');
    $('#sub_nav_menu').addClass('d-none');
  }
}
