import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="user-feedback"
export default class extends Controller {
  static targets = ['qtemplate', 'atemplate'];
  static values = { questions: Array, size: Number };

  connect() {
    this.userFeedback = document.getElementById('submitted_feedback');
    this.index = 1;
    this.written_feedback_chat();
  }

  pushEnter(e) {
    if (e.keyCode == 13) {
      this.addAnswer(e.currentTarget.value);
      e.currentTarget.value = '';
      
      if (this.index < this.sizeValue) {
        this.addQuestion(this.questionsValue[this.index][1]);
        this.index++;
    
      } else {
        document.getElementById('user-input').style.display = 'none';
        document.getElementById('user-submit').style.display = 'inline';
        document.getElementById('submitted_feedback').style.height = '62vh';
        if (this.userFeedback) {
          this.userFeedback.scrollTop = this.userFeedback.scrollHeight;
        }
      }
    }
  }

  pushEditEnter(e) {
    if (e.keyCode == 13) {
      e.currentTarget.classList.remove('editable');
      e.currentTarget.readOnly = true;
    }
  }

  submitAnswers(e) {
    const collection = document.getElementsByClassName('answer-text');
    let feedback_data = [];
    for (let i = 0; i < collection.length; i++) {
      let data = {
        question: this.questionsValue[i][1],
        answer: collection[i].value,
        position: this.questionsValue[i][2],
      };
      feedback_data.push(data);
    }
    document.querySelector(
      'input[name="submitted_feedback[feedback_data]"]',
    ).value = JSON.stringify(feedback_data);
  }

  editAnswer(e) {
    e.preventDefault();
    let nextSibling = e.target.nextElementSibling;
    nextSibling.classList.add('editable');
    nextSibling.readOnly = false;
  }

  addAnswer(data) {
    var content = this.atemplateTarget.innerHTML.replace(/ANSWER/g, data);
    this.userFeedback.insertAdjacentHTML('beforeend', content);
  }

  addQuestion(data) {
    let content = this.qtemplateTarget.innerHTML.replace(/QUESTION/g, data);
    this.userFeedback.insertAdjacentHTML('beforeend', content);
    this.written_feedback_chat();
    this.userFeedback.scrollTop = this.userFeedback.scrollHeight;
  };

  written_feedback_chat(){
    setTimeout(function () {
      $('#submitted_feedback .question-text').last().removeClass('d-none');
      $('#submitted_feedback .load').addClass('d-none');
      if (document.getElementById('submitted_feedback')) {
        let heightValue = document.getElementById('submitted_feedback').scrollHeight;
        $("#submitted_feedback").scrollTop(heightValue);
      }
    }, 3000);
  }
}
