import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'cardElement', 'cardErrors', 'form' ]

  connect() {
    const CANADIAN_TAXES = {
                            AB: 0.05,
                            BC: 0.12,
                            MB: 0.05,
                            NB: 0.15,
                            NL: 0.15,
                            NS: 0.15,
                            NT: 0.15,
                            NU: 0.05,
                            ON: 0.13,
                            PE: 0.15,
                            QC: 0.05,
                            SK: 0.05,
                            YT: 0.05
                          }

    const CANADA = 'Canada'
    var stripe = window.Stripe(this.data.get('key'));
    const appearance = {
      theme: 'stripe',
    };
    var elements = stripe.elements({ appearance });
    var style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
            color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    var card = elements.create('card', {
      style: style
    });

    card.mount(this.cardElementTarget);

    // Handle real-time validation errors from the card Element.
    let controller = this;
    card.addEventListener('change', function (event) {
      var displayError = controller.cardErrorsTarget;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    // Handle form submission.
    let form = controller.formTarget;
    let subtotal = $('#checkout-amount');
    let amount = parseFloat(subtotal.text().replace(/[^0-9.-]+/g,""));
    let checkout_taxes =  $('#checkout-taxes')
    let checkout_total =  $('#checkout-total')
    let total_amount
    form.country.addEventListener('change', function(event){
      console.log(event);
      this.url = 'states?country=' + this.value
      fetch(this.url, {
        headers: {
          Accept: 'text/vnd.turbo-stream.html'
        }
      })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
      if(this.value == CANADA) {
        checkout_taxes.html('5 %');
        total_amount = amount + amount * 0.05
        checkout_total.html('$' + total_amount);
      }
    });
    $(document).on("change","#province_or_state_or_region",function(event){
      console.log(this.value);
      if(document.getElementById('country').value == CANADA) {
        let taxes = parseFloat(CANADIAN_TAXES[this.value])
        checkout_taxes.html(taxes * 100 + ' %')
        total_amount = Number(amount + amount * taxes).toFixed(2)
        checkout_total.html('$' + total_amount);
      };
    });

    form.submit.addEventListener('click', function (event) {
      stripe.createToken(card).then(function (result) {
        if (result.error) {
          // Inform the user if there was an error.
          var errorElement = this.cardErrorsTarget;
          errorElement.textContent = result.error.message;
        } else {
          // Send the token to your server.
          controller.stripeTokenHandler(result.token);
        }
      });
    });
  }

  // Submit the form with the token ID.
  stripeTokenHandler(token) {
    // Insert the token ID into the form so it gets submitted to the server
    var form = this.formTarget;
    var hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripe_token');
    hiddenInput.setAttribute('value', token.id);
    form.appendChild(hiddenInput);
    form.requestSubmit();
  }
}