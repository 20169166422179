import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="dashboard"
export default class extends Controller {

  clickSubmittedBlock(){
    document.querySelector('.submitted-link').click();
  }

  clickRewardBlock(){
    document.querySelector('.reward-link').click();
  }

  clickCopyLink(e){
    e.preventDefault();
    this.url = this.element.getAttribute('href');
    navigator.clipboard.writeText(this.url).then(() => {
      console.log('Url copied to clipboard');
      document.getElementById("copiedAlert").style.display = "block";
      setTimeout(function() {
        document.getElementById("copiedAlert").style.display = "none";
      }, 2000);
      /* Resolved - url copied to clipboard successfully */
    },() => {
      console.error('Failed to copy');
      /* Rejected - url failed to copy to the clipboard */
    });
  }
}
