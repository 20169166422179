import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="nested-form"
export default class extends Controller {
  connect() {
    this.rearrange_counter();
  }
  static targets = ['add_item', 'template'];

  addAssociation(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf(),
    );
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content);
    this.rearrange_counter();
  }

  removeAssociation(event) {
    event.preventDefault();

    let wrapper = event.target.closest('.nested-fields');
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove();
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = 'none';
    }
    this.rearrange_counter();
  }

  rearrange_counter(){
    $('.question-counter').each(function(i, elm) {
      // Prepend a index span to each element
      $(elm).html('<span class="index">Question ' + (i + 1) + '</span>');
    });
  }
}
