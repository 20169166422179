import { Controller } from '@hotwired/stimulus';
import validate from 'validate.js';

// Connects to data-controller="validate"
export default class extends Controller {
  static targets = ['form', 'input', 'select'];

  onSubmit(event) {
    event.preventDefault();
    document.querySelector('#otSpinner').classList.remove('d-none');
    const errors = validate(this.formTarget, this.constraints());
    if (errors == null) {
      this.formTarget.requestSubmit();
    } else {
      document.querySelector('#otSpinner').classList.add('d-none');
      this.showErrors(errors);
    }
  }

  onFeedbackSubmit(event) {
    event.preventDefault();
    document.querySelector('#otSpinner').classList.remove('d-none');
    const errors = validate(this.formTarget, this.constraints());
    const presentQuesion = this.checkQuestions();
    if (errors == null && presentQuesion) {
      this.formTarget.requestSubmit();
    } else {
      document.querySelector('#otSpinner').classList.add('d-none');
      if (presentQuesion == false) {
        document.querySelector('.question-error').innerHTML = 'Add any questions!';
        $('.question-textarea').addClass('is-invalid');
      }
      this.showErrors(errors);
    }
  }

  addPreview(event) {
    event.preventDefault();
    document.getElementById('show_preview').value = true;
  }

  checkQuestions() {
    let result = false;
    $('.question-textarea').each(function(i, elm) {
      console.log(this.value)
      if (this.value && // if exist AND
          this.value.length > 0 && // if value have one charecter at least
          this.value.trim().length > 0 // if value is not just spaces
      )
      {
        result = this.value;
        return false;
      }
    })
    return result;
  }

  constraints() {
    let constraints = {};
    // for (let select of this.selectTargets) {
    // }
    for (let input of this.inputTargets) {
      constraints[input.name] = JSON.parse(input.getAttribute('data-validate'));
    }

    return constraints;
  }

  showErrors(errors) {
    for (let input of this.inputTargets) {
      this.showErrorForInput(input, errors[input.name]);
    }
  }

  showErrorForInput(input, errors) {
    this.clearMessages(input);
    if (errors) {
      input.classList.add('is-invalid');
      this.insertErrorMessage(input, errors);
    } else {
      if (input.classList.contains('is-invalid')) {
        this.insertSuccessMessage(input);
      }
      input.classList.add('is-valid');
      input.classList.remove('is-invalid');
    }
  }

  clearMessages(input) {
    let error = document.getElementById(`error_${input.name}`);
    let success = document.getElementById(`success_${input.name}`);

    if (error != null) {
      error.remove();
    }

    if (success != null) {
      success.remove();
    }
  }

  insertErrorMessage(input, errors) {
    let html = document.createElement('div');
    html.innerHTML = errors.join(', ');
    html.id = `error_${input.name}`;
    html.className = 'invalid-feedback';
    input.after(html);
  }

  insertSuccessMessage(input) {
    let html = document.createElement('div');
    html.innerHTML = 'Looks good!';
    html.id = `success_${input.name}`;
    html.className = 'valid-feedback';
    input.after(html);
  }
}
