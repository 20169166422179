import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this),
    });
  }

  end(event) {
    if (event.item.dataset.newRecord != 'true') {
      let id = event.item.dataset.id;
      let url = this.data.get('url').replace(':id', id);
      let data = new FormData();
      data.append('position', event.newIndex + 1);

      fetch(url, {
        body: data,
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': this.getMetaValue('csrf-token'),
        },
      }).then(function (response) {
        if (response.status != 200) {
          alert('Something went wrong. Please try again.');
        }
      });
    }
    $('.question-counter').each(function(i, elm) {
      // Prepend a index span to each element
      $(elm).html('<span class="index">Question ' + (i + 1) + '</span>');
    });
  }

  getMetaValue(name) {
    const element = this.findElement(document.head, `meta[name="${name}"]`);
    if (element) {
      return element.getAttribute('content');
    }
  }

  findElement(root, selector) {
    if (typeof root == 'string') {
      selector = root;
      root = document;
    }
    return root.querySelector(selector);
  }
}
