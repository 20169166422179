// Entry point for the build script in your package.json
import '@hotwired/turbo-rails';
import './add_jquery';
import './controllers';
import * as bootstrap from 'bootstrap';
import './libraries/jquery.ddslick.min'
import './custom.js';
import './controllers/functions';
import * as ActiveStorage from '@rails/activestorage';
ActiveStorage.start();
window.bootstrap = bootstrap;
