import { Controller } from '@hotwired/stimulus';

const cameraOptions = document.querySelector('.video-options>select');
const audioOptions = document.querySelector('.audio-options>select');
const cameraOptionsBottom = document.querySelector('.video-options_bottom>select');
const audioOptionsBottom = document.querySelector('.audio-options_bottom>select');
const video = document.querySelector('video');
const streamStarted = false;

const constraints = {
  audio: true,
  video: true,
};

// Connects to data-controller="video-prepare"
export default class extends Controller {
  connect() {
    let reloading = sessionStorage.getItem("first-reloading");
    if (reloading) {
      sessionStorage.removeItem("first-reloading");
      this.getStream().then(this.getDevices).then(this.gotDevices);
      // this.getCameraSelection().then(r => console.log('Camera connect'));
    } else {
      this.reloadPage();
    }
  }

  getDevices() {
    // AFAICT in Safari this only gets default devices until gUM is called :/
    return navigator.mediaDevices.enumerateDevices();
  }

  gotDevices(deviceInfos) {
    window.deviceInfos = deviceInfos; // make available to console
    console.log('Available input and output devices:', deviceInfos);
    for (const deviceInfo of deviceInfos) {
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `Microphone ${audioSelect.length + 1}`;
        audioOptions.appendChild(option);
      } else if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `Camera ${videoSelect.length + 1}`;
        cameraOptions.appendChild(option);
      }
    }
  }

 getStream() {
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    const audioSource = audioOptions.value;
    const videoSource = cameraOptions.value;
    const constraints = {
      audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
      video: {deviceId: videoSource ? {exact: videoSource} : undefined}
    };
    return navigator.mediaDevices.getUserMedia(constraints).
    then(this.gotStream).catch(this.handleError);
  }

  gotStream(stream) {
    window.stream = stream; // make stream available to console
    audioOptions.selectedIndex = [...audioOptions.options].
    findIndex(option => option.text === stream.getAudioTracks()[0].label);
    cameraOptions.selectedIndex = [...cameraOptions.options].
    findIndex(option => option.text === stream.getVideoTracks()[0].label);
    video.srcObject = stream;
  }

  changeCameraOptions = () => {
    console.log('changeCameraOptions ' + cameraOptions.value)
    this.getStream();
  };

  changeAudioOptions = () => {
    console.log('changeAudioOptions ' + audioOptions.value)
    this.getStream();
  };

  // startedStream = () => {
  //   if (streamStarted) {
  //     video.play();
  //     return;
  //   }
  //   if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
  //     const updatedConstraints = {
  //       ...constraints,
  //       deviceId: {
  //         exact: cameraOptions.value,
  //       },
  //       audio: {
  //         deviceId: {
  //           exact: audioOptions.value,
  //         },
  //       },
  //     };
  //     this.startStream(updatedConstraints);
  //   }
  // };

  // startStream = async (constraints) => {
  //   const stream = await navigator.mediaDevices.getUserMedia(constraints);
  //   console.log("stream" + stream)
  //   this.handleStream(stream);
  // };
  //
  // handleStream = (stream) => {
  //   video.srcObject = stream;
  // };

  videoRecording() {
    console.log('Quick Checklist');
    this.setCookie('otSetOptionsVideo', cameraOptions.value, 1);
    this.setCookie('otSetOptionsAudio', audioOptions.value, 1);

    document.getElementById('video-recording').classList.add('d-none');
    document.getElementById('video-checklist').classList.remove('d-none');
    document.getElementById('video-under').classList.remove('d-none');
  }

  videoChecklist() {
    console.log('Video Checklist');
    document.getElementById('video-checklist').classList.add('d-none');
    document.getElementById('video-testing').classList.remove('d-none');
    this.drawCanvasImage();
  }

  videoTesting() {
    console.log('Video Testing');
    document.getElementById('video-testing').classList.add('d-none');
    document.getElementById('video-sound').classList.remove('d-none');
    this.clearCanvas();

    //test microphone
    document.getElementById('video-sound') && navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true
    })
      .then(function(stream) {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
    
        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;
    
        microphone.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);
        scriptProcessor.onaudioprocess = function() {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          const arraySum = array.reduce((a, value) => a + value, 0);
          const average = arraySum / array.length;
          // console.log(Math.round(average));
          colorPids(average);
        };
      })
      .catch(function(err) {
        /* handle the error */
        console.error(err);
      });
    
    function colorPids(vol) {
      const allPids = [...document.querySelectorAll('.pid')];
      const numberOfPidsToColor = Math.round(vol / 7);
      const pidsToColor = allPids.slice(0, numberOfPidsToColor);
      for (const pid of allPids) {
        pid.style.opacity = '0'
      }
      for (const pid of pidsToColor) {
        pid.style.opacity = '1'
      }
    }
    // end test microphone
  }

  otUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }

  videoSound() {
    console.log('Video Sound');
    this.setCookie('otFeedbackUuid', this.otUuid(), 1);
    document.getElementById('video-sound').classList.add('d-none');
    document.getElementById('video-prepare').classList.add('d-none');
  }

  drawCanvasImage() {
    const canvas = document.getElementById('videoCanvas');
    canvas.classList.remove('d-none');
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    const context = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    context.setLineDash([8, 7]);
    const radius = 220;

    context.beginPath();
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    context.lineWidth = 2;
    context.stroke();
  }

  clearCanvas() {
    const canvas = document.getElementById('videoCanvas');
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  }

  // getCameraSelection = async () => {
  //   console.log('getCameraSelection');
  //   await navigator.mediaDevices.getUserMedia(constraints);
  //   const devices = await navigator.mediaDevices.enumerateDevices();
  //   const videoDevices = devices.filter(
  //     (device) => device.kind === 'videoinput',
  //   );
  //   const audioDevices = devices.filter(
  //     (device) => device.kind === 'audioinput',
  //   );
  //   const optionsVideo = videoDevices.map((videoDevice) => {
  //     return `<option value="${videoDevice.deviceId}">${videoDevice.label}</option>`;
  //   });
  //   const optionsAudio = audioDevices.map((videoDevice) => {
  //     return `<option value="${videoDevice.deviceId}">${videoDevice.label}</option>`;
  //   });
  //   console.log('getCameraSelection ' + optionsVideo + ' ' + optionsAudio);
  //   cameraOptions.innerHTML = optionsVideo.join('');
  //   audioOptions.innerHTML = optionsAudio.join('');
  //   cameraOptionsBottom.innerHTML = optionsVideo.join('');
  //   audioOptionsBottom.innerHTML = optionsAudio.join('');
  //   // this.startedStream();
  // };

  setCookie(name, value, days) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  reloadPage() {
    sessionStorage.setItem("first-reloading", "true");
    document.location.reload();
  }

  handleError(error) {
    console.error('Error: ', error);
  }
}
