import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element, {
      keyboard: false
    })
    this.modal.show();
    var form = this.element.querySelector('form');
    if (typeof(form) != 'undefined' && form != null)
    {
      form.addEventListener('turbo:submit-end', ({ target }) => {
        this.disconnect();
      })
    }
  }

  hideModal(){
    this.clear_session_id();
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }

  clear_session_id() {
    let params = new URLSearchParams(location.search)
    params.delete('session_id')
    history.replaceState(null, '', '?' + params + location.hash)
  }

  disconnect() {
    this.clear_session_id();
    this.modal.hide();
  }
}
