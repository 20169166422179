import { Controller } from '@hotwired/stimulus';

const video = document.querySelector('video#otVideo');
let recordButton = document.querySelector('button#otRecord');
const stopButton = document.querySelector('button#otStop');
const playButton = document.querySelector('button#otPlay');
const pauseButton = document.querySelector('button#otPause');
const nextControl = document.querySelector('#nextControl');
const controlAction = document.querySelector('.controls-actions');
let streamStarted = false;

const constraints = {
  audio: true,
  video: true,
};

let recordedVideo = document.querySelector('video#recorded');
let mediaRecorder;
let recordedBlobs;
let questionId;
let question;
let editId;
let preview;
let cameraOptions;
let audioOptions;
let videoValue;
let audioValue;
let otSpinner;

// Connects to data-controller="video-answer"
export default class extends Controller {
  static values = {
    url: String,
    questionId: String,
    question: String,
  };

  connect() {
    editId = this.get_edit_id();
    preview = this.get_preview();
    videoValue = this.getCookie('otSetOptionsVideo');
    audioValue = this.getCookie('otSetOptionsAudio');
    // this.getCameraSelection(false);
    this.getStream().then(this.getDevices).then(this.gotDevices);
    if (editId !== null) {
      document.getElementById('nextControl').classList.remove('d-none');
      document.getElementById('otRecord').classList.add('d-none');
      document.getElementById('userVideo').classList.remove('d-none');
      document.querySelector('.cameraVideo').classList.add('d-none');
      controlAction.classList.add('actioned')
    }
    this.checkSubmit();
    questionId = this.questionIdValue;
  }

  getCookie(name) {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  setCookie(name, value, days) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  getDevices() {
    // AFAICT in Safari this only gets default devices until gUM is called :/
    return navigator.mediaDevices.enumerateDevices();
  }

  gotDevices(deviceInfos) {
    window.deviceInfos = deviceInfos; // make available to console
    console.log('Available input and output devices:', deviceInfos);
    cameraOptions = document.querySelector('.video-options>select');
    audioOptions = document.querySelector('.audio-options>select');
    for (const deviceInfo of deviceInfos) {
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `Microphone ${audioSelect.length + 1}`;
        audioOptions.appendChild(option);
      } else if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `Camera ${videoSelect.length + 1}`;
        cameraOptions.appendChild(option);
      }
    }
    cameraOptions.value = videoValue;
    audioOptions.value = audioValue;
  }

  getStream() {
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    cameraOptions = document.querySelector('.video-options>select');
    audioOptions = document.querySelector('.audio-options>select');
    cameraOptions.value = this.getCookie('otSetOptionsVideo');
    audioOptions.value = this.getCookie('otSetOptionsAudio');
    let audioSource = audioOptions.value || audioValue;
    let videoSource = cameraOptions.value || videoValue;

    const constraints = {
      audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
      video: {deviceId: videoSource ? {exact: videoSource} : undefined}
    };
    return navigator.mediaDevices.getUserMedia(constraints).
    then(this.gotStream).catch(this.handleError);
  }

  gotStream(stream) {
    window.stream = stream; // make stream available to console
    audioOptions.selectedIndex = [...audioOptions.options].
    findIndex(option => option.text === stream.getAudioTracks()[0].label);
    cameraOptions.selectedIndex = [...cameraOptions.options].
    findIndex(option => option.text === stream.getVideoTracks()[0].label);
    // video.srcObject = stream;
    document.querySelector('video#otVideo').srcObject = stream;
    }

  changeCameraOptions = () => {
    cameraOptions = document.querySelector('.video-options>select');
    this.setCookie('otSetOptionsVideo', cameraOptions.value, 1);
    console.log('changeCameraOptions ' + cameraOptions.value)
    this.getStream();
  };

  changeAudioOptions = () => {
    audioOptions = document.querySelector('.audio-options>select');
    this.getStream();
    this.setCookie('otSetOptionsAudio', audioOptions.value, 1);
  };

  // startedStream = () => {
  //   if (streamStarted) {
  //     video.play();
  //     return;
  //   }
  //   if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
  //     cameraOptions = document.querySelector('.video-options>select');
  //     audioOptions = document.querySelector('.audio-options>select');
  //     const updatedConstraints = {
  //       ...constraints,
  //       deviceId: {
  //         exact: cameraOptions.value,
  //       },
  //       audio: {
  //         deviceId: {
  //           exact: audioOptions.value,
  //         },
  //       },
  //     };
  //     this.startStream(updatedConstraints);
  //   }
  // };

  pauseStream = () => {
    mediaRecorder.stop();
    video.pause();
    document.querySelector('#nextControl').classList.remove('d-none');
    document.querySelector('button#otStop').classList.add('d-none');
    controlAction.classList.add('actioned')
  };

  // startStream = async (constraints) => {
  //   const stream = await navigator.mediaDevices.getUserMedia(constraints);
  //   this.handleStream(stream);
  // };
  //
  // handleStream = (stream) => {
  //   console.log('getUserMedia() got stream:', stream);
  //   window.stream = stream;
  //   document.querySelector('video#otVideo').srcObject = stream;
  // };

  // getCameraSelection = async (start) => {
  //   await navigator.mediaDevices.getUserMedia(constraints);
  //   const devices = await navigator.mediaDevices.enumerateDevices();
  //   const videoDevices = devices.filter(
  //     (device) => device.kind === 'videoinput',
  //   );
  //   const audioDevices = devices.filter(
  //     (device) => device.kind === 'audioinput',
  //   );
  //   const optionsVideo = videoDevices.map((videoDevice) => {
  //     return `<option value="${videoDevice.deviceId}">${videoDevice.label}</option>`;
  //   });
  //   const optionsAudio = audioDevices.map((videoDevice) => {
  //     return `<option value="${videoDevice.deviceId}">${videoDevice.label}</option>`;
  //   });
  //   cameraOptions = document.querySelector('.video-options>select');
  //   audioOptions = document.querySelector('.audio-options>select');
  //   cameraOptions.innerHTML = optionsVideo.join('');
  //   audioOptions.innerHTML = optionsAudio.join('');
  //   cameraOptions.value = this.getCookie('otSetOptionsVideo');
  //   audioOptions.value = this.getCookie('otSetOptionsAudio');
  //
  //   if (editId == null || start == true) {
  //     this.startedStream();
  //   }
  // };

  recordClick() {
    // console.log('got window.stream:', window.stream);
    document.getElementById('clock').classList.remove('d-none');
    var timeleft = 3;
    var downloadTimer = setInterval(() => {
      if (timeleft <= 0) {
        clearInterval(downloadTimer);
        document.getElementById('clock').classList.add('d-none');
        document.querySelector('button#otRecord').classList.add('d-none');
        document.querySelector('button#otStop').classList.remove('d-none');
        this.startRecording();
      } else {
        document.getElementById('seconds').innerHTML = timeleft;
      }
      timeleft -= 1;
    }, 1000);
  }

  startRecording() {
    recordedBlobs = [];
    const mimeType = this.getSupportedMimeTypes()[0];
    const options = { mimeType };

    try {
      mediaRecorder = new MediaRecorder(window.stream, options);
    } catch (e) {
      console.error('Exception while creating MediaRecorder:', e);
      errorMsgElement.innerHTML = `Exception while creating MediaRecorder: ${JSON.stringify(
        e,
      )}`;
      return;
    }

    console.log(
      'Created MediaRecorder',
      mediaRecorder,
      'with options',
      options,
    );
    mediaRecorder.onstop = (event) => {
      console.log('Recorder stopped: ', event);
      console.log('Recorded Blobs: ', recordedBlobs);
    };
    mediaRecorder.ondataavailable = this.handleDataAvailable;
    mediaRecorder.start();
    console.log('MediaRecorder started', mediaRecorder);
  }

  recordAgain() {
    if (editId !== null) {
      document.getElementById('userVideo').classList.add('d-none');
    }
    document.querySelector('.cameraVideo').classList.remove('d-none');
    document.querySelector('video#recorded').classList.add('d-none');
    document.querySelector('video#otVideo').classList.remove('d-none');
    document.getElementById('otRecord').classList.remove('d-none');
    document.getElementById('nextControl').classList.add('d-none');
    controlAction.classList.remove('actioned')
    // this.getCameraSelection(true);
    this.getStream();
  }

  recordPlay() {
    document.querySelector('button#otPause').classList.remove('d-none');
    document.querySelector('button#otPlay').classList.add('d-none');
    if (editId == null) {
      recordedVideo = document.querySelector('video#recorded');
      recordedVideo.classList.remove('d-none');
      document.querySelector('video#otVideo').classList.add('d-none');
      const mimeType = this.getSupportedMimeTypes()[0].split(';', 1)[0];
      const superBuffer = new Blob(recordedBlobs, { type: mimeType });
      recordedVideo.src = null;
      recordedVideo.srcObject = null;
      recordedVideo.src = window.URL.createObjectURL(superBuffer);
      recordedVideo.controls = true;
      recordedVideo.play();
    } else {
      document.getElementById('userVideo').play();
    }
  }

  recordPause() {
    document.querySelector('button#otPause').classList.add('d-none');
    document.querySelector('button#otPlay').classList.remove('d-none');
    if (editId == null) {
      recordedVideo.classList.remove('d-none');
      document.querySelector('video#otVideo').classList.add('d-none');
      const mimeType = this.getSupportedMimeTypes()[0].split(';', 1)[0];
      const superBuffer = new Blob(recordedBlobs, { type: mimeType });
      recordedVideo.src = null;
      recordedVideo.srcObject = null;
      recordedVideo.src = window.URL.createObjectURL(superBuffer);
      recordedVideo.controls = true;
      recordedVideo.pause();
    } else {
      document.getElementById('userVideo').pause();
    }
  }

  // recordDownload() {
  //   const blob = new Blob(recordedBlobs, { type: 'video/webm' });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.style.display = 'none';
  //   a.href = url;
  //   a.download = 'test.webm';
  //   document.body.appendChild(a);
  //   a.click();
  //   setTimeout(() => {
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);
  //   }, 100);
  // }

  recordNext() {
    document.querySelector('#otSpinner').classList.remove('d-none');
    var fd = new FormData();
    if (preview) {
      fd.append('feedback_video_part[preview]', true);
    }
    if (document.querySelector('.cameraVideo').classList.contains('d-none')) {
      fd.append('feedback_video_part[edit]', false);
    } else {
      const blob = new Blob(recordedBlobs, { type: 'video/webm' });
      fd.append('feedback_video_part[file]', blob);
    }
    fd.append('feedback_video_part[question_id]', questionId);
    fd.append(
      'feedback_video_part[feedback_uuid]',
      this.getCookie('otFeedbackUuid'),
    );
    if (editId) {
      this.submitEditToAPI(fd);
    } else {
      this.submitToAPI(fd);
    }
  }

  submitToAPI(data) {
    fetch(document.location.origin + '/feedback_video_parts', {
      method: 'POST',
      body: data,
    })
      .then((response) => response.json())
      .then((answer) => this.checkAnswer(answer))
      .catch((error) => console.error(error));
  }

  submitEditToAPI(data) {
    fetch(document.location.origin + '/feedback_video_parts/' + editId, {
      method: 'PUT',
      body: data,
    })
      .then((response) => response.json())
      .then((answer) => this.checkAnswer(answer))
      .catch((error) => console.error(error));
  }

  checkAnswer(answer) {
    // console.log(JSON.stringify(answer, null, 2));
    document.querySelector('#otSpinner').classList.add('d-none');
    const message = answer['message'];
    switch (message) {
      case 'Last':
        this.reloadPage();
        break;
      case 'Success next':
      case 'Success update':
      case 'Success':
        let next_url =
          '/f/' +
          answer['account_slug'] +
          '/' +
          answer['feedback_uuid'] +
          '/' +
          answer['question_id'];
          if (answer['preview'] == 'true'){
            next_url = next_url + '?preview=true'
          }
        this.clear_edit_id()
        window.location.pathname = next_url;
        break;
      case 'Need UUID':
        let start_url =
          '/f/' + answer['account_slug'] + '/' + answer['feedback_uuid'];
        window.location.pathname = start_url;
        break;
      default:
        console.log(`Sorry, we don't understand ${message}.`);
    }
  }

  checkSubmit(){
    let reloading = sessionStorage.getItem("last-reloading");
    if (reloading) {
      sessionStorage.removeItem("last-reloading");
      // window.stream.getTracks().forEach((track) => track.stop());
      document.getElementById('user-submit').style.display = 'inline';
      document.getElementById('video-submit').classList.remove('d-none');
      document.getElementById('otNext').style.display = 'none';
      document.getElementById('video-questions').classList.add('d-none');
    }
  }

  submitAnswers(e) {
    document.querySelector(
      'input[name="submitted_feedback[video_uuid]"]',
    ).value = this.getCookie('otFeedbackUuid');
  }

  handleDataAvailable(event) {
    console.log('handleDataAvailable', event);
    if (event.data && event.data.size > 0) {
      recordedBlobs.push(event.data);
    }
  }

  getSupportedMimeTypes() {
    const possibleTypes = [
      'video/webm;codecs=vp9,opus',
      'video/webm;codecs=vp8,opus',
      'video/webm;codecs=h264,opus',
      'video/mp4;codecs=h264,aac',
    ];
    return possibleTypes.filter((mimeType) => {
      return MediaRecorder.isTypeSupported(mimeType);
    });
  }

  get_edit_id() {
    let url = new URL(window.location);
    return url.searchParams.get('edit_id');
  }

  clear_edit_id() {
    let params = new URLSearchParams(location.search)
    params.delete('edit_id')
    history.replaceState(null, '', '?' + params + location.hash)
  }

  get_preview() {
    let url = new URL(window.location);
    return url.searchParams.get('preview');
  }

  reloadPage() {
    sessionStorage.setItem("last-reloading", "true");
    document.location.reload();
  }
}
